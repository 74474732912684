<template>
  <v-container>
    <page-title
      :page-title="!$language ? 'Course Details' : 'কোর্সের বিবরণ'"
      class="mt-5 mb-2"
    >
    </page-title>
    <lazy-load :item="course">
      <course-form-p :course="course" :hide-btn="true"></course-form-p>
    </lazy-load>
  </v-container>
</template>

<script>
import CourseFormP from "#ecf/course/components/CourseFormP";

export default {
  name: "MCourseDetails",
  components: { CourseFormP },
  props: {
    courseId: String,
    partner_code: String
  },
  data: () => ({}),
  computed: {
    course() {
      if (this.$store.state.course.courseMap[this.partner_code] !== undefined) {
        return this.$store.state.course.courseMap[this.partner_code][
          this.courseId
        ];
      } else {
        return undefined;
      }
    }
  }
};
</script>
