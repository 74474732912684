var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("page-title", {
        staticClass: "mt-5 mb-2",
        attrs: {
          "page-title": !_vm.$language ? "Course Details" : "কোর্সের বিবরণ",
        },
      }),
      _c(
        "lazy-load",
        { attrs: { item: _vm.course } },
        [
          _c("course-form-p", {
            attrs: { course: _vm.course, "hide-btn": true },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }